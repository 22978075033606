<template>
  <v-app>
    <v-app-bar
      app
      color="green"
      dark
    >
      <div class="d-flex align-center">
        <!--<v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        />-->

        <v-spacer></v-spacer>

        <router-link to="/" style="text-decoration: none; color: white">
          <v-toolbar-title class="shrink mr-2 flex text-center">
            PrivateAnime
          </v-toolbar-title>
        </router-link>
      </div>

      <v-spacer></v-spacer>
      <v-toolbar-title class="mr-2 hidden-sm-and-down">Pre-Alpha</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container>
        <!--<AnimePlayer video="/files/Azur Lane/E01.mp4" subtitles="/files/Azur Lane/E01.vtt" />-->

        <transition name="slide" mode="out-in">
          <router-view></router-view>
        </transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data: () => ({
    //
  }),
};
</script>

<style>
  .slide-enter-active,
  .slide-leave-active {
    transition: opacity 1s;

  }
  .slide_enter,
  .slide_leave_to {
    opacity: 0;
    transform: translateX(-40%);
  }
</style>
