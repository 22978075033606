<template>
  <div>
    <VideoPlayer :subtitles="subtitles" :options="videoOptions"/>
  </div>
</template>

<script>
import VideoPlayer from "./VideoPlayer";

export default {
  name: "AnimePlayer",
  components: {VideoPlayer},
  props: ["video", "subtitles"],
  data() {
    return {
      videoOptions: {
        autoplay: true,
        controls: true,
        sources: [
          {
            src: this.video,
            type: "video/mp4"
          }
        ],
      },
      fluid: true,
    };
  }
}
</script>

<style scoped>

</style>
