<template>
  <v-container>
    <h1>Welcome to PrivateAnime!</h1>
    <h2>Anime List:</h2>
    <v-container fluid v-if="!loaded">
        <v-progress-circular
            indeterminate
            :size="70"
            color="green"
            class="text-center"
        />
    </v-container>
    <div v-else>
      <v-container>
        <v-row>
          <v-col v-for="anime in animeList" v-bind:key="anime" cols="4">
            <AnimeCover class="" :name="anime"></AnimeCover>
            <v-spacer></v-spacer>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-container>
</template>

<script>
import AnimeCover from "./components/AnimeCover";

export default {
  name: "LandingPageAnimeListing",
  components: {AnimeCover},
  mounted() {
    fetch("/animelist.php")
    .then((res) => { return res.json() })
    .then((animes) => {
      this.animeList = animes;
      this.loaded = true;
    });
  },
  data() {
    return {
      animeList: [],
      loaded: false
    }
  }
}
</script>

<style scoped>
AnimeCover {
  padding: 20px;
}
</style>
