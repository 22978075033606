<template>
  <div>
    <div v-if="loaded">
      <AnimePlayer :video="videoFile" :subtitles="subtitleFile"></AnimePlayer>
      <router-link
          v-if="this.nextEPAvailable()"
          :to="nextEPLink">
        <v-btn
            color="primary"
            elevation="3"
            x-large
            class="ma-4 float-right">Next episode</v-btn>
      </router-link>

      <br />
      <v-divider></v-divider>

      <v-container class="mt-6">
        <v-row>
          <v-col cols="6">
            <AnimeCover :name="name" />
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-title>EP{{episode}} - {{ animeData.episodes[episode-1][1] }}</v-card-title>
              <v-card-text>
                {{ animeData.episodes[episode-1][2] }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-else>
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import AnimePlayer from "./AnimePlayer";
import AnimeCover from "./AnimeCover";
export default {
name: "AnimeWatch",
  components: {AnimeCover, AnimePlayer},
  props: ["name", "episode"],
  computed: {
    videoFile() {
      return `/files/${this.name}/EP${this.episode}.mp4`
    },
    subtitleFile() {
      return `/files/${this.name}/EP${this.episode}.vtt`
    },
    descriptionLink() {
      return `/files/${this.name}/description.json`;
    },
    nextEPLink() {
      return `/anime/${this.name}/watch/${parseInt(this.episode)+1}`;
    }
  },
  data() {
    return {
      animeData: {},
      loaded: false
    }
  },
  mounted() {
    fetch(this.descriptionLink)
        .then((res) => { return res.json() })
        .then((res) => {
          this.animeData = res;
          this.loaded = true;
        })
  },
  methods: {
    nextEPAvailable() {
      return this.animeData !== undefined && !(this.animeData.episodes[this.animeData.episodes.length-1][0] <= this.episode);
    }
  }
}
</script>

<style scoped>

</style>
