<template>
  <div>
    <video ref="videoPlayer" class="video-js vjs-big-play-centered vjs-fluid"></video>
  </div>
</template>

<style>
.video-js.vjs-fluid,
.video-js.vjs-16-9,
.video-js.vjs-4-3,
video.video-js,
video.vjs-tech {
  max-height: calc(100vh - 70px);
  position: relative !important;
  width: 100%;
  height: auto !important;
  max-width: 100% !important;
  padding-top: 0 !important;
  line-height: 0;
}

.vjs-control-bar {
  line-height: 1;
}
</style>

<script>
import videojs from 'video.js';
require("videojs-seek-buttons");

export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    subtitles: {
      type: String,
      default() {
        return "";
      }
    },
  },
  data() {
    return {
      player: null
    }
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
      console.log('onPlayerReady', this);
    });
    this.player.seekButtons({
      forward: 30,
      back: 10
    });
    if(this.subtitles !== "") {
      videojs(this.$refs.videoPlayer, null, () => {
        videojs(this.$refs.videoPlayer).addRemoteTextTrack({
          kind: 'subtitles',
          label: 'Subs',
          src: this.subtitles,
          mode: "showing",
          default: true
        }, false);
        videojs(this.$refs.videoPlayer).textTrackSettings.setValues({
          "backgroundColor": "#000",
          "backgroundOpacity": "0.5",
        });

        console.log("Added " + this.subtitles);
      })
    }
    this.player.load();
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  }
}
</script>
