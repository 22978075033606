<template>

    <v-card
        max-width="374">
       <router-link :to="animeLink" style="text-decoration: none">
       <v-img
        :src="coverLink"
       ></v-img>
       <v-card-title>
        {{ name }}
       </v-card-title>
      </router-link>
    </v-card>
</template>

<script>
export default {
  name: "AnimeCover",
  props: ["name"],
  computed: {
    coverLink() {
      return `/files/${this.name}/cover.jpg`;
    },
    animeLink() {
      return `/anime/${this.name}`;
    },
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
