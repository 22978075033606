<template>
  <div>
    <div v-if="loaded">
      <div>
        <v-container>
          <v-row>
            <v-col cols="6">
              <AnimeCover :name="name" />
            </v-col>
            <v-col cols="6">
              <v-card>
                <v-card-title>{{ name }}</v-card-title>
                <v-card-text>
                  {{ animeData.description }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <br />
        <v-divider></v-divider>
        <v-card class="ma-2">
          <v-card-title>
            Episodes
          </v-card-title>

          <v-row v-bind:key="ep[0]" v-for="ep in animeData.episodes">
              <v-col>
                <router-link :to="getLinkForEP(ep[0])">
                  <v-card-title>EP{{ ep[0] }} - {{ ep[1] }}</v-card-title>
                </router-link>
                <v-card-text style="text-decoration: none">{{ ep[2] }}</v-card-text>
                <v-divider></v-divider>
              </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
    <div v-else>
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import AnimeCover from "./AnimeCover";

export default {
  name: "AnimeView",
  props: ["name"],
  components: {AnimeCover},
  data() {
    return {
      animeData: undefined,
      loaded: false
    }
  },
  computed: {
    descriptionLink() {
      return `/files/${this.name}/description.json`;
    },
    animeWatchLink() {
      return `/anime/${this.name}/watch/`;
    },
  },
  mounted() {
    fetch(this.descriptionLink)
      .then((res) => { return res.json() })
      .then((res) => {
        this.animeData = res;
        this.loaded = true;
      })
  },
  methods: {
    getLinkForEP(episodeNumber) {
      return this.animeWatchLink + episodeNumber;
    }
  }
}
</script>

<style scoped>
  a {
    color: white !important;
  }
</style>
