import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import routes from "./plugins/router";
import VueRouter from 'vue-router'

Vue.config.productionTip = false
window.VIDEOJS_NO_DYNAMIC_STYLE = true

import "video.js/dist/video-js.min.css";
require("videojs-seek-buttons/dist/videojs-seek-buttons.css");

Vue.use(VueRouter);
console.log(routes)

const router = new VueRouter({
  routes
});


new Vue({
  vuetify,
  router: router,
  render: h => h(App)
}).$mount('#app')
